import React, { FC } from "react"
import { navigate } from "gatsby"
import styled from "styled-components"
import { up } from "styled-breakpoints"
import Select from "react-select"
import idx from "idx"

import { color, text } from "../theme"
import Text from "./text"
import { SimpleTextLink } from "./link"
import { UTMContext } from "./utmcontext"

export const F = styled.form`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;
  align-content: flex-start;
  align-items: flex-start;
  justify-items: flex-start;
  margin: 0 auto;
`

export const Input = styled.input`
  font-family: "SpaceGrotesk";
  ${text("body")};
  height: 35px;
  border: none;
  padding: 2px 8px;
  line-height: 35px;
  justify-self: stretch;
  color: ${color("black")};
  background-color: ${color("white")};
  border: 1px solid ${color("lightgrey")};
`

const Email = styled(Input)``
const Label = styled.label`
  position: absolute;
  visibility: hidden;
  color: ${color("black")};
`

export const Button = styled.button`
  cursor: pointer;
  display: block;
  border: none;
  outline: none;
  height: 35px;
  min-width: 100px;
  padding: 0;
  line-height: 35px;
  background-color: ${color("yellow")};
  transition: all 0.3s ease-out;
  &:hover {
    background-color: ${color("black")};
    color: ${color("white")};
  }
  ${up("desktop")} {
    padding: 0 2rem;
  }
`

export const Loading = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  &:after {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 10px;
    background-color: ${color("black")};
    animation: loader-anim 1.3s infinite;
    position: absolute;
    top: calc(50% - 5px);
    left: calc(50% - 5px);
  }
`
export const Check = styled.input`
  margin: 0.5rem;
  margin-left: 0;
`

const Legal = styled.div`
  display: grid;
  grid-gap: 0.5rem;
`
const PLink = styled(SimpleTextLink)`
  font-size: 0.6rem;
`
export const TextArea = styled.textarea`
  font-family: "SpaceGrotesk";
  ${text("body")};
  align-self: stretch;
  justify-self: stretch;
  padding: 1rem;
  border: 1px solid ${color("lightgrey")};
`
export const CSelect = styled(Select)`
  align-self: stretch;
  justify-self: stretch;
  div {
    border-radius: 0;
  }
`

const options = [
  { value: "google", label: "Google" },
  { value: "linkedin", label: "LinkedIn" },
  { value: "twitter", label: "Twitter" },
  { value: "magazine", label: "Magazine" },
  { value: "events", label: "Events" },
]
const companyTypeOptions = [
  { value: "Escrow", label: "Escrow" },
  { value: "Marketplace", label: "Marketplace" },
  { value: "Gig economy", label: "Gig economy" },
  { value: "Sharing economy", label: "Sharing economy" },
  { value: "Fintech", label: "Fintech" },
  { value: "Blockchain/Crypto", label: "Blockchain/Crypto" },
  { value: "Other", label: "Other" },
]

export type Props = {
  btnCopy?: string
  type: FormType
}

const getDataForType = (type: FormType, target: HTMLFormElement) =>
  Object.entries({
    type: idx(target, _ => _.type.value) || null,
    firstname: idx(target, _ => _.firstname.value) || null,
    lastname: idx(target, _ => _.lastname.value) || null,
    email: idx(target, _ => _.email.value) || null,
    company: idx(target, _ => _.company.value) || null,
    medium: idx(target, _ => _.medium.value) || null,
    cf_term: idx(target, _ => _.cf_term.value) || null,
    utm_campaign: idx(target, _ => _.utm_campaign.value) || null,
    utm_source: idx(target, _ => _.utm_source.value) || null,
    companyType: idx(target, _ => _.companyType.value) || null,
    heard: idx(target, _ => _.heard.value) || null,
    message: idx(target, _ => _.message.value) || null,
  })
    .filter(([k, v]) => v !== null)
    .reduce((a, [k, v]) => ({ ...a, [k]: v }), {})

export const LegalBit: React.FC = () => (
  <Legal>
    <Text type="smallcopy">
      <Check type="checkbox" name="agree" required />
      By ticking this box, you agree to us contacting you by email and storing
      your contact details for future contact about the Paybase GmbH products
      and services.
    </Text>
    <Text type="smallcopy">
      Your contact details will be accessible by employees of Paybase GmbH,
      but will never be shared with or sold to third parties. Submitting your
      details also indicates that you have read and agree to our{" "}
      <PLink to="/privacy-policy" color="blue" hoverColor="red">
        privacy policy
      </PLink>
      . You can, of course, opt out of these communications at any time!
    </Text>
  </Legal>
)

const customSelectStyle = {
  menu: (provided: any) => ({
    ...provided,
    zIndex: 10,
  }),
  menuList: (provided: any) => ({
    ...provided,
    padding: 0,
    margin: 0,
    zIndex: 999,
  }),
  option: (provided: any, state: any) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#ff88a4" : "transparent",
    ":hover": {
      backgroundColor: state.isSelected ? "#ff88a4" : "#89e6ff",
    },
    color: "#303030",
  }),
  input: (provided: any) => ({
    ...provided,
    color: "#303030",
    fontSize: "1rem",
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: "1.38",
    letterSpacing: "normal",
  }),
}

const Form: FC<Props> = ({ type, btnCopy }) => {
  const [loading, setLoading] = React.useState(false)
  const params = React.useContext(UTMContext)
  const onsubmit = (e: React.FormEvent<HTMLFormElement>) => {
    const data = getDataForType(type, e.currentTarget)
    setLoading(true)
    fetch("/.netlify/functions/marketing", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then(r => {
      setLoading(false)
      if (r.status === 200) {
        ;(window.dataLayer || []).push({ event: "formSubmission" })
        switch (type) {
          case "whitepaper":
            navigate("/thank-you-enquiry-whitepaper")
            break
          case "get-in-touch-sandbox":
            navigate("/thank-you-enquiry-sandbox")
            break
          default: {
            switch (params.utm_campaign) {
              case "Escrow":
                navigate("/thank-you-enquiry-escrow")
                break
              case "Remarketing-2019":
                navigate("/thank-you-enquiry-retargeting")
                break
              case "Branding":
                navigate("/thank-you-enquiry-branding")
                break
              case "Competitors":
                navigate("/thank-you-enquiry-competitors")
                break
              case "Display-2020":
                navigate("/thank-you-enquiry-display")
                break
              case "Marketplaces":
                navigate("/thank-you-enquiry-marketplace")
                break
              case "Crypto":
                navigate("/thank-you-enquiry-blockchain")
                break
              case "Gig-economy":
                navigate("/thank-you-enquiry-gig")
                break
              case "Sharing-economy":
                navigate("/thank-you-enquiry-sharing")
                break
              case "Fintech":
                navigate("/thank-you-enquiry-fintech")
                break
              default:
                navigate("/thank-you-enquiry")
                break
            }
          }
        }
      } else
        alert("Something went wrong, please try again later or contact paybase")
    })
    e.preventDefault()
  }
  return (
    <F onSubmit={onsubmit}>
      <Label htmlFor="get-in-touch-firstname">Firstname</Label>
      <Input
        required
        id="get-in-touch-firstname"
        name="firstname"
        type="text"
        placeholder="First name"
      />
      <Label htmlFor="get-in-touch-lastname">lastname</Label>
      <Input
        required
        id="get-in-touch-lastname"
        name="lastname"
        type="text"
        placeholder="Last name"
      />
      <Label htmlFor="get-in-touch-email">email</Label>
      <Email
        required
        id="get-in-touch-email"
        name="email"
        type="email"
        placeholder="E-mail address"
      />
      <Label htmlFor="company">company</Label>
      <Input id="company" name="company" type="text" placeholder="Company" />
      {type !== "get-in-touch-sandbox" && (
        <>
          <Label htmlFor="heard">heard</Label>
          <CSelect
            id="heard"
            name="heard"
            placeholder="How did you hear about us?"
            options={options}
            styles={customSelectStyle}
          />
        </>
      )}
      <>
        <Label htmlFor="companyType">company type</Label>
        <CSelect
          id="companyType"
          name="companyType"
          placeholder="What kind of company are you?"
          options={companyTypeOptions}
          styles={customSelectStyle}
        />
      </>
      {!["whitepaper", "get-in-touch-sandbox"].includes(type) && (
        <>
          <Label id="message" htmlFor="message">
            Message
          </Label>
          <TextArea
            required
            name="message"
            rows={2}
            placeholder="Write your message here..."
          ></TextArea>
        </>
      )}
      <input type="hidden" name="type" value={type} />
      <input type="hidden" name="medium" value={params.utm_medium || ""} />
      <input
        type="hidden"
        name="utm_campaign"
        value={params.utm_campaign || ""}
      />
      <input type="hidden" name="utm_source" value={params.utm_source || ""} />
      <input type="hidden" name="cf_term" value={params.utm_term || ""} />
      <LegalBit />
      <Button disabled={loading} type="submit">
        {loading ? <Loading /> : btnCopy || "Subscribe"}
      </Button>
    </F>
  )
}

export default Form
